import { type Assistant, type Message } from '@langchain/langgraph-sdk';
import { useStream } from '@langchain/langgraph-sdk/react';
import { useSearchParams } from '@remix-run/react';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { type DtoGamePack } from '@lp-lib/api-service-client/public';

import { type FeatureQueryParamArrays } from '../../../hooks/useFeatureQueryParam';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { err2s } from '../../../utils/common';
import { ThreadMessage } from '../../Agent/Message';
import { GraphUtils, useLoadAssistant } from '../../Agent/utils';
import { useUGCFileManager } from '../../Game/UGC/CustomGameFileManager';
import { CustomGamePackPromptEditor } from '../../Game/UGC/CustomGamePackPromptEditor';
import { linkAgentThreadToGamePack } from '../utils';
import { type TrainingEditorControlAPI } from './TrainingEditorControlAPI';

type State = {
  messages: Message[];
  courseId: string | null;
  uid: string | null;
  config?: Record<string, unknown>;
};

function MessageList(props: { stream: ReturnType<typeof useStream<State>> }) {
  const { stream } = props;
  const ref = useRef<HTMLDivElement>(null);

  const messagesCount = stream.messages.length;
  const lastMessage = stream.messages[stream.messages.length - 1];

  const scrollToBottom = useLiveCallback(() => {
    if (!ref.current) return;
    const scrollHeight = ref.current.scrollHeight;
    const height = ref.current.clientHeight;
    const maxScrollTop = scrollHeight - height;
    ref.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  });

  useLayoutEffect(() => scrollToBottom(), [scrollToBottom]);
  useLayoutEffect(
    () => scrollToBottom(),
    [
      messagesCount,
      scrollToBottom,
      lastMessage?.content?.length,
      stream.isLoading,
    ]
  );

  return (
    <div ref={ref} className='w-full flex-1 overflow-y-auto scrollbar my-4'>
      <div className='w-full min-h-full flex-1 flex flex-col gap-2 justify-end text-white px-2.5'>
        {stream.messages.map((message, idx) => (
          <ThreadMessage
            key={message.id}
            message={message}
            showLoading={
              stream.isLoading &&
              message.type === 'ai' &&
              idx === stream.messages.length - 1
            }
          />
        ))}
      </div>
    </div>
  );
}

function Main(props: {
  pack: DtoGamePack;
  ctrl: TrainingEditorControlAPI;
  assistant: Assistant;
  rebuildStores: () => Promise<void>;
  agentMode: FeatureQueryParamArrays['agentic'][number];
}) {
  const { pack, assistant, agentMode } = props;
  const fileman = useUGCFileManager();
  const [searchParams, setSearchParams] = useSearchParams();

  const [threadId, setThreadId] = useState<string | null>(
    pack.ugcSettings?.trainingAgentThreadId ?? null
  );
  const rebuildStores = useDebouncedCallback(props.rebuildStores, 1000);

  const stream = useStream<State>({
    apiUrl: GraphUtils.API_URL,
    assistantId: assistant.assistant_id,
    threadId,
    onThreadId: (threadId) => {
      setThreadId(threadId);
    },
    onFinish: () => {
      props.rebuildStores();
    },
  });

  const lastMessage = stream.messages.at(stream.messages.length - 1);

  useEffect(() => {
    if (
      lastMessage &&
      lastMessage.type === 'tool' &&
      lastMessage.name?.match(/^create|update|delete|reorder/)
    ) {
      rebuildStores();
    }
  }, [lastMessage, rebuildStores]);

  const ensureThreadId = useLiveCallback(async (threadId: string) => {
    await linkAgentThreadToGamePack(pack, threadId);
  });

  const submitFromOutline = useLiveCallback(async () => {
    const from = searchParams.get('from');
    if (from !== 'outline') return;
    searchParams.delete('from');
    setSearchParams(searchParams, { replace: true });
    stream.submit(
      {
        config: {
          ...stream.values.config,
          useCodeAgent: agentMode === 'code',
        },
        courseId: pack?.id ?? null,
      },
      { onDisconnect: 'continue' }
    );
  });

  useLayoutEffect(() => {
    submitFromOutline();
  }, [submitFromOutline]);

  useEffect(() => {
    if (!threadId) return;
    ensureThreadId(threadId);
  }, [threadId, ensureThreadId]);

  useEffect(() => {
    fileman.init(pack.id, []);
    fileman.disable();
  }, [pack?.id, fileman]);

  const handleSubmit = useLiveCallback(async (message: string) => {
    if (stream.interrupt) {
      stream.submit(undefined, {
        command: { resume: message },
        onDisconnect: 'continue',
      });
    } else {
      stream.submit(
        {
          config: {
            ...stream.values.config,
            useCodeAgent: agentMode === 'code',
          },
          courseId: pack?.id ?? null,
          messages: [{ type: 'human', content: message }],
        },
        { onDisconnect: 'continue' }
      );
    }
    return true;
  });

  const newThread = useLiveCallback(async () => {
    const client = GraphUtils.CreateClient();
    const thread = await client.threads.create();
    setThreadId(thread.thread_id);
  });

  return (
    <div className='w-full h-full flex flex-col gap-4'>
      <div className='w-full flex-none text-white text-sms flex justify-between items-center gap-2'>
        <div className='flex items-center gap-2'></div>
        <button type='button' className='btn text-primary' onClick={newThread}>
          Clear
        </button>
      </div>

      <MessageList stream={stream} />

      <div className='w-full flex-none'>
        <CustomGamePackPromptEditor
          enabled
          onSubmit={handleSubmit}
          onAbort={() => stream.stop()}
          isSubmitting={stream.isLoading}
          active
          autoFocus
          disableDeactivate
          bottomLabel=''
          placeholder='How can I help you today?'
          wrapperClassName='mb-4'
          width='w-full'
        />
      </div>
    </div>
  );
}

export function TrainingEditorAgentChatSidebar(props: {
  pack: DtoGamePack;
  ctrl: TrainingEditorControlAPI;
  rebuildStores: () => Promise<void>;
  agentMode: FeatureQueryParamArrays['agentic'][number];
}) {
  const {
    data: assistant,
    error,
    isLoading,
  } = useLoadAssistant('block-crafter');

  if (isLoading) return null;
  if (error) {
    return (
      <div className='text-red-002'>
        Error loading assistant: {err2s(error)}
      </div>
    );
  }
  if (!assistant) {
    return (
      <div className='text-red-002'>Assistant not found: {err2s(error)}</div>
    );
  }

  return (
    <Main
      pack={props.pack}
      ctrl={props.ctrl}
      assistant={assistant}
      rebuildStores={props.rebuildStores}
      agentMode={props.agentMode}
    />
  );
}
