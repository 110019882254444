import { nanoid } from '@reduxjs/toolkit';
import { type Editor, type KeyboardShortcutCommand, Node } from '@tiptap/core';
import {
  type NodeViewProps,
  NodeViewWrapper,
  ReactNodeViewRenderer,
} from '@tiptap/react';
import { Plugin, PluginKey, type Transaction } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';
import { useRef, useState } from 'react';

import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { useMedia } from '../../../hooks/useMedia';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { ImagePickPriorityLowToHigh, MediaUtils } from '../../../utils/media';
import { ImageIcon } from '../../icons/ImageIcon';
import { DialogueEditorMarkTutorQuestion } from './DialogueEditorMarkTutorQuestion';
import { type DialogueMarkerType } from './types';
import { DialogueUtils } from './utils';

export interface MarkOptions {
  enabledTypes: DialogueMarkerType[];
  onChange: (editor: Nullable<Editor>) => void;
}

interface HighlightPluginState {
  highlightRange: { from: number; to: number } | null;
}

// Create a plugin key for our highlight plugin
const highlightPluginKey = new PluginKey('highlight');
export { highlightPluginKey };

// Highlight plugin for text highlighting when clicking on images
const createHighlightPlugin = () => {
  return new Plugin<HighlightPluginState>({
    key: highlightPluginKey,
    state: {
      init(): HighlightPluginState {
        return {
          highlightRange: null,
        };
      },
      apply(tr: Transaction, prev: HighlightPluginState): HighlightPluginState {
        const meta = tr.getMeta(highlightPluginKey);
        if (!meta) return prev;

        if (meta.type === 'set-highlight') {
          // If we have a mediaId, find its position and calculate range to next image
          if (!meta.mediaId) return prev;

          const { doc } = tr;

          // Find all image marks with their positions and mediaIds
          const imageMarks: Array<{ pos: number; mediaId: string }> = [];
          doc.descendants((node, pos) => {
            if (node.type.name === 'mark' && node.attrs.type === 'image') {
              imageMarks.push({ pos, mediaId: node.attrs.name });
            }
            return true;
          });

          // Sort by position to maintain document order
          imageMarks.sort((a, b) => a.pos - b.pos);

          // Find current image position
          const currentIndex = imageMarks.findIndex(
            (mark) => mark.mediaId === meta.mediaId
          );
          if (currentIndex === -1) return { highlightRange: null };

          const currentPos = imageMarks[currentIndex].pos;
          const endPos =
            currentIndex < imageMarks.length - 1
              ? imageMarks[currentIndex + 1].pos
              : doc.nodeSize - 2; // Default to end of document if it's the last image

          return {
            highlightRange: { from: currentPos + 1, to: endPos },
          };
        }

        // clear the highlight
        return {
          highlightRange: null,
        };
      },
    },
    props: {
      decorations(state) {
        const pluginState = this.getState(state);
        if (!pluginState || !pluginState.highlightRange)
          return DecorationSet.empty;

        const { highlightRange } = pluginState;
        const decorations = [
          Decoration.inline(highlightRange.from, highlightRange.to, {
            class: 'highlighted-text',
          }),
        ];

        return DecorationSet.create(state.doc, decorations);
      },
    },
  });
};

// Keyboard shortcuts for trigger marks
const createTriggerShortcuts = (
  editor: Editor
): Record<string, KeyboardShortcutCommand> => {
  const createTriggerMark = () => {
    return editor
      .chain()
      .command(({ state, tr }) => {
        const type = editor.schema.nodes.mark;
        const { $from } = tr.selection;

        if (!$from.parent.canReplaceWith($from.index(), $from.index(), type)) {
          return false;
        }

        const preferredTriggers = [...Array(10).keys()].map(
          (i) => `trigger-${i + 1}`
        );

        state.doc.descendants((node) => {
          if (node.type.name === 'mark' && node.attrs.type === 'trigger') {
            const index = preferredTriggers.indexOf(node.attrs.name);
            if (index !== -1) {
              preferredTriggers.splice(index, 1);
            }
          }
        });

        const name = preferredTriggers.shift() ?? `trigger-${nanoid(4)}`;

        tr.replaceSelectionWith(
          type.create({
            type: 'trigger',
            name,
          })
        );
        return true;
      })
      .run();
  };

  return {
    'Ctrl-Space': createTriggerMark,
    'Ctrl-Shift-T': createTriggerMark,
  };
};

export const DialogueEditorMark = Node.create<MarkOptions>({
  name: 'mark',
  inline: true,
  group: 'inline',
  draggable: true,
  selectable: true,
  atom: true,

  addOptions() {
    return {
      enabledTypes: ['trigger', 'image', 'tutor-question'],
      onChange: () => {
        // just a placeholder
      },
    };
  },

  addAttributes() {
    return {
      type: {
        default: 'trigger',
      },
      name: {
        default: '',
      },
      keywords: {
        default: '',
      },
      query: {
        default: '',
      },
      question: {
        default: '',
      },
      finishCriteria: {
        default: '',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'mark',
      },
    ];
  },

  renderHTML({ node }) {
    return [
      'mark',
      {
        type: node.attrs.type,
        name: node.attrs.name,
        keywords: node.attrs.keywords,
        query: node.attrs.query,
        question: node.attrs.question,
        finishCriteria: node.attrs.finishCriteria,
      },
      0,
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(MarkComponent);
  },

  addProseMirrorPlugins() {
    const enabledTypes = this.options.enabledTypes;

    if (enabledTypes.includes('image')) {
      return [createHighlightPlugin()];
    }

    return [];
  },

  addKeyboardShortcuts() {
    const enabledTypes = this.options.enabledTypes;
    const shortcuts: Record<string, KeyboardShortcutCommand> = {};

    if (enabledTypes.includes('trigger')) {
      Object.assign(shortcuts, createTriggerShortcuts(this.editor));
    }

    return shortcuts;
  },
});

function MarkComponent(props: NodeViewProps) {
  const type = props.node.attrs.type as DialogueMarkerType;

  switch (type) {
    case 'trigger':
      return <TriggerMarkComponent {...props} />;
    case 'image':
      return <ImageMarkComponent {...props} />;
    case 'tutor-question':
      return <DialogueEditorMarkTutorQuestion {...props} />;
    default:
      console.log('type', type);
      // assertExhaustive(type);
      return null;
  }
}

function ImageMarkComponent(props: NodeViewProps) {
  const { editor } = props;
  const { name: mediaId, query } = props.node.attrs;

  const imageRef = useRef<HTMLDivElement>(null);
  const [isActive, setIsActive] = useState(false);

  const { media } = useMedia(mediaId);

  const url = MediaUtils.PickMediaUrl(media, {
    priority: ImagePickPriorityLowToHigh,
  });

  const handleImageClick = () => {
    editor.view.dispatch(
      editor.state.tr.setMeta(highlightPluginKey, {
        type: 'set-highlight',
        mediaId,
      })
    );

    setIsActive(true);
  };

  const handleOutsideClick = useLiveCallback(() => {
    editor.view.dispatch(
      editor.state.tr.setMeta(highlightPluginKey, {
        type: 'clear-highlight',
      })
    );

    setIsActive(false);
  });

  useOutsideClick(imageRef, handleOutsideClick, null, !isActive);

  return (
    <NodeViewWrapper
      as='div'
      type='image'
      media-id={mediaId}
      query={query}
      className={`group inline-block align-middle cursor-pointer`}
    >
      <div
        ref={imageRef}
        onClick={handleImageClick}
        className={`w-6 h-4 flex items-center justify-center rounded-sm border-2 ${
          isActive ? 'border-[#4CE3B3]' : 'border-transparent'
        }`}
      >
        {url ? (
          <img src={url} alt='' className='w-full h-full object-cover' />
        ) : (
          <ImageIcon className={`w-4 h-4 fill-current text-icon-gray`} />
        )}
      </div>
    </NodeViewWrapper>
  );
}

function TriggerMarkComponent(props: NodeViewProps) {
  const { name } = props.node.attrs;

  return (
    <NodeViewWrapper className='inline-block' type='trigger' name={name}>
      {name.startsWith('trigger-') ? (
        <span className='trigger-point' title={name}>
          {DialogueUtils.GetTriggerDisplayName(name) ?? '🚩'}
        </span>
      ) : (
        <span className='text-icon-gray font-medium' title={name}>
          [trigger: {name}]
        </span>
      )}
    </NodeViewWrapper>
  );
}
